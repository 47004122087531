// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: #1a1a1a;
}

.hero-section {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hero-section > div.relative {
  z-index: 2;
}

input::placeholder {
  color: #1a1a1a;
}

button {
  border: 2px solid transparent;
}

button:hover {
  border-color: #FC5600;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

header h1 {
  margin: 0;
}

/* Style the icons */
.icon-link {
  color: #FC5600;
  transition: color 0.3s;
}

.icon-link:focus, .icon-link:hover {
  color: #FC5600; /* Ensure hover and focus are the same color */
}

/* Footer styles */
footer {
  background-color: #1a1a1a;
  text-align: center;
  padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,gCAAgC;EAChC,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,2BAA2B;EAC3B,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,SAAS;AACX;;AAEA,oBAAoB;AACpB;EACE,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,cAAc,EAAE,8CAA8C;AAChE;;AAEA,kBAAkB;AAClB;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".App {\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n\nbody {\n  margin: 0;\n  font-family: 'Inter', sans-serif;\n  background-color: #1a1a1a;\n}\n\n.hero-section {\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  position: relative;\n}\n\n.overlay {\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 1;\n}\n\n.hero-section > div.relative {\n  z-index: 2;\n}\n\ninput::placeholder {\n  color: #1a1a1a;\n}\n\nbutton {\n  border: 2px solid transparent;\n}\n\nbutton:hover {\n  border-color: #FC5600;\n}\n\nheader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 1rem;\n}\n\nheader h1 {\n  margin: 0;\n}\n\n/* Style the icons */\n.icon-link {\n  color: #FC5600;\n  transition: color 0.3s;\n}\n\n.icon-link:focus, .icon-link:hover {\n  color: #FC5600; /* Ensure hover and focus are the same color */\n}\n\n/* Footer styles */\nfooter {\n  background-color: #1a1a1a;\n  text-align: center;\n  padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
